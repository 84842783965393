import * as React from 'react'
import styled from 'styled-components'

// ASSETS
// COMPONENTS
import { Button } from '../styled/button'
import { Paragraph } from '../styled/typography'
import Checkbox from '../styled/checkbox'
import { Plan } from '../styled/plan'

const Container = styled.div``
const StyledButton = styled(Button)`
  margin-top: calc(var(--spacing) * 6);
  width: 100%;
`

const StyledPlan = styled(Plan)`
  cursor: default;
  background-color: var(--secondary);
  border: none;
`
const Hr = styled.div`
  height: 0;
  border-top: 1px dashed var(--primary-light);
  margin: calc(var(--spacing) * 9) 0;
`

const PaymentDetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const CardDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1 1;
  }

  > p {
    font-weight: 500;
  }
`

const StyledParagraph = styled(Paragraph)`
  margin-bottom: 40px;
`
const Confirmation = ({ onConfirm, selectedPlan, loading }) => {
  const validate = e => {
    if (!checked) {
      alert('Please check the confirmation box')
      return
    }
    onConfirm(e)
  }

  const [checked, setChecked] = React.useState(false)
  return (
    <Container>
      <StyledParagraph>You are paying</StyledParagraph>
      <StyledPlan>
        <h6>
          <b>{selectedPlan.price}$</b>
        </h6>
        <small>{selectedPlan.shortDescription}</small>
      </StyledPlan>
      <Hr />
      <PaymentDetailsContainer>
        <Paragraph>You will be paying</Paragraph>
        <CardDetails>
          <Paragraph>${selectedPlan.price}</Paragraph>
          {/*<div>
            ****4421
            <MasterCard />
          </div>*/}
        </CardDetails>
      </PaymentDetailsContainer>
      <Checkbox onChange={e => setChecked(e.target.checked)} value={checked}>
        <Paragraph>
          I agree on <a href={'/terms'}>terms & Conditions</a>
        </Paragraph>{' '}
      </Checkbox>
      {loading && <span>Please wait..</span>}
      {!loading && <StyledButton onClick={validate}>Confirm</StyledButton>}
    </Container>
  )
}

export default Confirmation
