import * as React from 'react'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby-link'
import Modal from 'react-modal'
// COMPONENTS
import Confetti from 'svgs/confetti.svg'
import SubscriptionCard from './subscriptionCard'
import Confirmation from './confirmationStep'
import FinalStep from './finalStep'
import { SCREEN } from '../../styles/screens'
import { Paragraph } from '../styled/typography'
import { Button } from '../styled/button'

import { Tab, Tabs } from '../styled/tabs'
import { Plan } from '../styled/plan'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { getTranslation } from '../../data/dictionary'
import axios from 'axios'
import { config } from '../../config'
import BecomeStudentOne from './becomeStudentOne'
import BecomeStudentTwo from './becomeStudentTwo'

let stripePromise = loadStripe('pk_live_51JkCI4L51W4ffuQ8P0WaJl6xhMv8pj4Lq1BEBEBSKFkOnS41FNszPbmxVuboGf3XzV3oJvcR8RmnAzJGcorZVT2j00NHRQlWEv')

const Container = styled.div`
  display: flex;

  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    flex-wrap: wrap;
    flex-direction: column;
  }
`

const LeftSide = styled.div`
  flex: 0 0 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  background-color: var(--secondary);
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    ${p => (!p.selected ? 'display: none;' : '')}
    flex: 0 0 100%;
    padding: 16px;
  }

  > div {
    max-width: 480px;
  }
`
const RightSide = styled.div`
  flex: 0 0 50%;
  display: flex;
  align-items: flex-start;
  justify-content: stretch;

  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    width: 100%;
    ${p => (!p.selected ? 'display: none;' : '')}
  }
`

const StyledH4 = styled.h4`
  line-height: 0.9;
  text-transform: uppercase;
  margin: 0;
  letter-spacing: -1px;
  color: ${({ inverted }) => (inverted ? 'transparent' : 'var(--primary)')};
  -webkit-text-stroke: 1px var(--primary);
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    -webkit-text-stroke: 1px var(--primary);
  }
`

const SemiBoldText = styled.span`
  font-weight: 500;
`

const ChangePackageButton = styled(Button)`
  background-color: var(--secondary-light);
`
const SelectedPlan = styled(Plan)`
  cursor: default;
  background-color: var(--secondary-light);
  border: none;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`
const SummaryContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: calc(var(--spacing) * 4) 0;
`
const Summery = ({ selectedPlan, changePlan }) => {
  return (
    <div>
      <SummaryContainer>
        <Paragraph>Your Summary</Paragraph>
      </SummaryContainer>
      <SelectedPlan>
        <h6>
          <b>{selectedPlan.price}$</b> one time payment
        </h6>
        <small>Refundable</small>

      </SelectedPlan>
    </div>
  )
}

const titles = {
  0: [
    { content: 'BECOME A', inverted: true },
    { content: 'STUDENT', inverted: false },
  ],
  2: [{ content: 'CONFIRMATION', inverted: true }],
  3: [
    { content: 'AWESOME', inverted: true },
    { content: 'NEWS', inverted: false, icon: Confetti },
  ],
}

const SubscriptionLayout = () => {
  const [plans, setPlans] = useState([])
  const [currentStep, setCurrentStep] = useState(0)
  const [selectedPlan, setSelectedPlan] = useState({price:50})
  const [paymentData, setPaymentData] = useState({})
  const [tab, setTab] = useState('description')

  React.useEffect(async () => {
    try {
      const token = window.localStorage.getItem('token')
      let user = (
        await axios.get(`${config.API_BASE_URL}/users/profile?token=${token}`)
      ).data
      let plans = (await axios.get(`${config.API_BASE_URL}/plans`)).data
      setPlans(plans)
    } catch (e) {
      window.localStorage.removeItem('user')
      window.localStorage.removeItem('token')
      window.location.href = '../../../public/subscription'
    }
  }, [])

  const parseCard = paymentData => {
    let { number, cvc, exp } = paymentData
    let parts = exp.split('/')
    let exp_month = parts[0]
    let exp_year = parts[1]
    return { number, cvc, exp_month, exp_year }
  }
  const makePayment = async () => {
    /*    Stripe.createPaymentMethod({type: 'card',card:parseCard(paymentData)}, function(status, response) {
          console.log(status, response)
        })*/
  }

  const steps = {
    0: (
      <BecomeStudentTwo
        onPay={() => {
          setCurrentStep(2)
        }}
        selectedPlan={selectedPlan}
      />
    ),
    1: (
      <Confirmation
        selectedPlan={selectedPlan}
        paymentData={paymentData}
        onConfirm={makePayment}
      />
    ),
    2: (
      <FinalStep
        onFinal={() => {
          navigate(`/profile`)
        }}
      />
    ),
  }
  const isLastStep = currentStep === 2
  return (
    <Elements stripe={stripePromise}>
      <Container>
        <Tabs>
          <Tab
            activeTab={tab === 'description'}
            aria-hidden
            tabIndex={0}
            onClick={() => setTab('description')}
          >
            Description
          </Tab>
          <Tab
            activeTab={tab === 'application'}
            aria-hidden
            tabIndex={0}
            onClick={() => setTab('application')}
          >
            Application
          </Tab>
        </Tabs>
        <LeftSide selected={tab === 'description'}>
          <div>
            <StyledH4 inverted>THANK YOU</StyledH4>
            <StyledH4>for your interest</StyledH4>
            {selectedPlan && (
              <Summery
                selectedPlan={selectedPlan}
                changePlan={() => {
                  setSelectedPlan(null)
                  setCurrentStep(0)
                }}
              />
            )}

            <h6>Your Benefits</h6>
            <Paragraph>
              {!selectedPlan &&
                getTranslation(
                  'your_benefits_general_description',
                  'your_benefits_general_description',
                )}
              {selectedPlan && currentStep === 0 && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: 'You will reserve your place for the upcoming batch.',
                  }}
                />
              )}
              {selectedPlan && currentStep === 0 && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: selectedPlan[`descriptionStep2`],
                  }}
                />
              )}
            </Paragraph>
          </div>
        </LeftSide>
        <RightSide selected={tab === 'application'}>
          {!isLastStep ? (
            <SubscriptionCard titles={titles[currentStep]} isLeft={!isLastStep}>
              {steps[currentStep]}
            </SubscriptionCard>
          ) : (
            <Modal
              isOpen={isLastStep}
              contentLabel={'Registration Modal'}
              ariaHideApp={false}
              className="react_modal_centered"
              overlayClassName="react_modal_register_overlay"
              closeTimeoutMS={1000}
            >
              <SubscriptionCard
                titles={titles[currentStep]}
                onClose={() => navigate('/')}
                isLeft={false}
              >
                {steps[2]}
              </SubscriptionCard>
            </Modal>
          )}
        </RightSide>
      </Container>
    </Elements>
  )
}

export default SubscriptionLayout
