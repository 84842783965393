import * as React from 'react'

import Dashboard from './dashboard'

import Layout from 'layouts/main'
import StudentSubscriptionLayout from '../components/studentSubscription/studentSubscriptionLayout'

const SubscriptionPage = () => (
  <Layout title={'Subscription'}>
     <StudentSubscriptionLayout /> 
    {/* <Dashboard /> */}
  </Layout>
)

export default SubscriptionPage
