import * as React from 'react'
import styled from 'styled-components'
import { SCREEN } from 'styles/screens'
// COMPONENTS
import { Card } from '../styled/card'
import CloseIcon from 'svgs/close.svg'
import BackIcon from 'svgs/modal_back.svg'

const StyledCard = styled(Card)`
  position: relative;
  padding: 48px;
  max-width: 584px;
  margin: ${p =>
    p.isLeft
      ? 'calc(var(--spacing) * 20) 0 calc(var(--spacing) * 20) calc(var(--spacing) * -20)'
      : '0'};
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    margin: 0;
    max-width: 100%;
    padding: 20px;
    width: 100%;
    ${p => (p.isLeft ? 'border-radius: 0; box-shadow: none;' : 'width: 90vw;')}
  }

  > svg:first-child {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }

  > svg:nth-child(2) {
    position: absolute;
    top: 16px;
    left: 16px;
    cursor: pointer;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  > p {
    > span {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`

export const HeadingsContainer = styled.div`
  margin-bottom: 16px;
  align-self: flex-start;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    width: 100%;
    text-align: center;
    margin-bottom: 0;
  }
`

export const StyledH2 = styled.h2`
  line-height: 0.9;
  margin: 0;
  letter-spacing: -4px;
  color: ${({ inverted }) => (inverted ? 'transparent' : 'var(--primary)')};
  -webkit-text-stroke: 2.7px var(--primary);
  font-size: 62px;
  text-align: left;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    -webkit-text-stroke: 1.3px var(--primary);
    font-size: 3rem;
  }
  user-select: none;
  word-break: break-all;

  &:hover {
    color: ${({ inverted }) =>
      inverted ? 'var(--primary)' : 'var(--secondary)'};
  }
`
export const StyledH3 = styled.h3`
  line-height: 0.9;
  margin: 0;
  letter-spacing: -4px;
  color: ${({ inverted }) => (inverted ? 'transparent' : 'var(--primary)')};
  -webkit-text-stroke: 2.7px var(--primary);
  font-size: 3rem;
  text-align: left;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    -webkit-text-stroke: 1.3px var(--primary);
    font-size: 3rem;
  }
  user-select: none;
  word-break: break-all;

  &:hover {
    color: ${({ inverted }) =>
      inverted ? 'var(--primary)' : 'var(--secondary)'};
  }
`

const SubscriptionCard = ({
  onClose,
  titles,
  children,
  isLeft,
  mainIcon: MainIcon,
  hasBack,
  onBack,
}) => {
  return (
    <StyledCard isLeft={isLeft}>
      {onClose && <CloseIcon onClick={onClose} />}
      {hasBack && <BackIcon onClick={onBack} />}
      <Content>
        {MainIcon && (
          <div>
            <MainIcon />
          </div>
        )}
        <HeadingsContainer>
          {titles.map(({ style, inverted, content, icon: Icon }) => {
            return style === 'h3' ? (
              <StyledH3 inverted={inverted} key={content}>
                {content} {Icon ? <Icon /> : ''}
              </StyledH3>
            ) : (
              <StyledH2 inverted={inverted} key={content}>
                {content} {Icon ? <Icon /> : ''}
              </StyledH2>
            )
          })}
        </HeadingsContainer>
        {children}
      </Content>
    </StyledCard>
  )
}

export default SubscriptionCard
